(function($){

    var openSpeed = 400,
        closeSpeed = 400,
        device = "mobile",
        $container = $("#list"),
        apis = [];

    $window = $(window);

    function initEqual() {
        "mobile" == resizeSite() ? $(".js-equalize").removeAttr("style") : ("desktop" == resizeSite() || "tablet" == resizeSite()) && $(".js-equalize").equalize()
    }

    function resizeSite() {
        return $(window).width() <= 768 ? "mobile" : $(window).width() <= 960 ? "tablet" : "desktop"
    }

    function openShareTwitter(e, t, i) {
        var n = "";
        return n = t ? t : e, window.open("http://twitter.com/share?url=" + n + "&text=" + i + "&", "twitterwindow", "height=450, width=550, top=" + ($(window).height() / 2 - 225) + ", left=" + $(window).width() / 2 + ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"), !1
    }

    function openShareFacebook(e, t, i) {
        var n = "";
        return n = t ? t : e, window.open("http://www.facebook.com/sharer/sharer.php?u=" + n + "&", "twitterwindow", "height=450, width=550, top=" + ($(window).height() / 2 - 225) + ", left=" + $(window).width() / 2 + ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"), !1
    }

    function createShortUrl(e, t) {
        var i = "032ce77fba62b115791f26d41a0a04e3bc997450",
            e = "https://api-ssl.bitly.com/v3/shorten?access_token=" + i + "&longUrl=" + encodeURIComponent(e);
        $.getJSON(e, {}, function(e) {
            t && t(e.data.url)
        })
    }

    function loaded() {
        document.getElementById("programmaContentScroll") && (myScroll = new iScroll("programmaContentScroll", {
            hideScrollbar: !1
        }))
    }

    function mobileFunctions() {
        $("body").addClass("mobile"), $("#text").addClass("mobile"), $("#text .mainBlock .wrapper").hide(), $("#text h1.title").click(function() {
            content = $(this).next(), content.is(":hidden") ? ($(this).addClass("active"), content.slideDown(openSpeed)) : ($(this).removeClass("active"), content.slideUp(closeSpeed))
        }), $("#text .mainBlock:first-child h1.title").trigger("click"), $("#photo").css("background-image", "url(" + $("#photo").attr("data-imageMobile") + ")")
    }

    function unMobileFunctions() {
        $("body").removeClass("mobile"), $("#text").removeClass("mobile"), $("#planning_1,#planning_2").select2({
            minimumResultsForSearch: "20"
        })
    }

    function desktopFunctions() {
        $(".program").attr("style", ""), $("body").hasClass("home") && $("#planning_1,#planning_2").select2({
            minimumResultsForSearch: "20"
        }), $("body").addClass("desktop"), $("#photo").css("background-image", "url(" + $("#photo").attr("data-imageDesktop") + ")"), $(".newsWrapper ul").length > 0 && $(".newsWrapper ul li").size() > 2 && ($(".newsWrapper ul li:not(:first)").hide(), fadeElement()), $submenu = $("#submenu"), $submenu.length > 0 && (elTop = $submenu.offset().top, $window.scroll(function() {
            $submenu.toggleClass("sticky", $window.scrollTop() > elTop)
        }))
    }

    function unDesktopFunctions() {
        $("body").removeClass("desktop"), $("#planning_1, #planning_2").select2("destroy"), $("#programmaContent").find(".programmaContent").css("height", "")
    }

    function setBackground() {
        if (!$("body").hasClass("home")) {
            body = $("#background"),
            bckImage = body.attr("data-background"),
            bckImageActual = body.css("background-image").replace(/^url\(['"]?/, "").replace(/['"]?\)$/, ""),
            device = resizeSite(),
            "mobile" != device && (device = "desktop");
            var e = templateDir + "/images/" + device + "/" + bckImage;
            bckImageActual != e && body.css("background-image", "url(" + e + ")")
        }
    }

    function setHomeImage() {
        if ($("body").hasClass("home")) {
            if (device = resizeSite(),
                imgContainer = $("#photoSlide"),
                imgPhoto = $("#photoSlide .photo"),
                imgHeight = $(imgContainer).outerHeight(!0),
                imgActual = imgPhoto.css("background-image").replace(/^url\(['"]?/, "").replace(/['"]?\)$/, ""), "mobile" == device) {
                    var e = $("#header").height();
                    containerHeight = imgHeight - e
            } else {
                var t = $("#headerDesktop").height(),
                    i = $("#topNav").height();
                containerHeight = imgHeight - (t + i), device = "desktop"
            }
            var n = templateDir + "/images/" + device + "/bld-home.jpg";
            imgPhoto.attr("data-background") && (n = imgPhoto.attr("data-background")), $(imgContainer).length > 0 && imgActual != n && $(imgPhoto).css("background-image", "url(" + n + ")"), $(".container-fixed").height(containerHeight)
        }
    }

    function menuHeight() {
        $menu = $("#menu"), windowHeight = $(document).height(), $menu.css("min-height", windowHeight - 60 + "px")
    }

    function triggerMenu(e) {
        "undefined" != typeof e && null !== e && "hide" == e ? ($("#menu .wrapper").slideUp(closeSpeed), $("#menu .background").fadeOut(closeSpeed, function() {
            $("#menu").hide(0)
        }), $(".menu-icon").removeClass("active")) : $("#menu").is(":hidden") ? (menuHeight(), $("#menu").show(0), $("#menu .wrapper").slideDown(openSpeed), $("#menu .background").fadeIn(openSpeed), $(".menu-icon").addClass("active")) : ($("#menu .wrapper").slideUp(closeSpeed), $("#menu .background").fadeOut(closeSpeed, function() {
            $("#menu").hide(0)
        }), $(".menu-icon").removeClass("active"))
    }

    function triggerSearch(e) {
        "undefined" != typeof e && null !== e && "hide" == e ? ($("#photo").animate({
            top: "0px"
        }, openSpeed), $("#kaart").animate({
            top: "0px"
        }, openSpeed), $("#search").slideUp(closeSpeed), $(".search-icon a").removeClass("active")) : $("#search").is(":hidden") ? ($("#photo").animate({
            top: "130px"
        }, openSpeed), $("#kaart").animate({
            top: "130px"
        }, openSpeed), $("#search").slideDown(openSpeed), $(".search-icon a").addClass("active")) : ($("#photo").animate({
            top: "0px"
        }, openSpeed), $("#kaart").animate({
            top: "0px"
        }, openSpeed), $("#search").slideUp(closeSpeed), $(".search-icon a").removeClass("active"))
    }

    function loadLazyImages() {
        $images = $("img.lazy"), $images.lazyload({
            failure_limit: Math.max($images.length - 1, 0),
            threshold: 200
        })
    }

    var ie = function() {
            for (var e, t = 3, i = document.createElement("div"), n = i.getElementsByTagName("i"); i.innerHTML = "<!--[if gt IE " + ++t + "]><i></i><![endif]-->", n[0];);
            return t > 4 ? t : e
        }(),
    myScroll;
    if (9 > ie || document.addEventListener("DOMContentLoaded", function() {
            setTimeout(loaded, 200)
        }, !1), void 0 !== ie && 9 > ie) var jRes = jRespond([{
            label: "desktop",
            enter: 0,
            exit: 1e4
        }]);
    else var jRes = jRespond([{
            label: "mobile",
            enter: 0,
            exit: 767
        }, {
            label: "tablet",
            enter: 768,
            exit: 959
        }, {
            label: "desktop",
            enter: 960,
            exit: 1e4
    }]);
    jRes.addFunc({
        breakpoint: "mobile",
        enter: function() {
            mobileFunctions()
        },
        exit: function() {
            unMobileFunctions()
        }
    }),
    jRes.addFunc({
        breakpoint: ["tablet", "desktop"],
        enter: function() {
            desktopFunctions()
        },
        exit: function() {
            unDesktopFunctions()
        }
    });

    $(document).ready(function(){
        window.scrollTo(0, 1),
        resizeSite();
        setBackground();
        setHomeImage();
        loadLazyImages(),
        $(".toggleView .big").click(function(e) {
            e.preventDefault(), $("#content").animate({
                "margin-top": "450"
            }, 400), setTimeout(function() {
                programmaKaart.loadSimpel()
            }, 400), $(this).hide(), $(".toggleView .small").show()
        }),
        $(".toggleView .small").click(function(e) {
            e.preventDefault(), $("#content").animate({
                "margin-top": "150"
            }, 400), setTimeout(function() {
                programmaKaart.hide("all")
            }, 400),
            $(this).hide(), $(".toggleView .big").show()
        }),
        $(".toggleView .photo").click(function(e) {
            e.preventDefault(),
            loaded || loadGoogleMaps(),
            $("#map").animate({
                top: "0"
            }, openSpeed),
            $(this).hide(), $(".toggleView .map").show()
        }),
        $(".toggleView .map").click(function(e) {
            e.preventDefault(),
            $("#map").animate({
                top: "-100%"
            }, openSpeed),
            $(this).hide(), $(".toggleView .photo").show()
        }),
        $(".search-icon").click(function(e) {
            e.preventDefault(),
            triggerMenu("hide"),
            triggerSearch()
        }),
        $(".menu-icon").click(function(e) {
            e.preventDefault(),
            triggerMenu(),
            triggerSearch("hide")
        }),
        $("#submenu a").click(function(e) {
            e.preventDefault(),
            scrollto = $(this).attr("href"),
            newPosition = $(scrollto).offset().top - $(submenu).outerHeight(),

            // newPosition = 200,
            $window.scrollTo({
                top: newPosition,
                left: 0
            }, openSpeed)
        });
     });

})(jQuery);
